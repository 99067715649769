<template>
  <div>
    <footer class="container">
      <p>&copy; Sulek 2025 &dash; Sulek is not affiliated with, endorsed, sponsored, or specifically approved by Sulake Suomi or its Affiliates.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
